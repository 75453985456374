@font-face {
	font-family: 'MrsEavesSmallCaps';
	src: url('./@fonts/MrsEavesSmallCaps.woff2') format('woff2'),
		url('./@fonts/MrsEavesSmallCaps.woff') format('woff');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'ScalaSansOffc';
	src: url('./@fonts/ScalaSansOffc.woff2') format('woff2'),
		url('./@fonts/ScalaSansOffc.woff2') format('woff');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

body {
	margin: 0;
	font-family: 'ScalaSansOffc', -apple-system, BlinkMacSystemFont, 'Segoe UI',
		'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
		'Helvetica Neue', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}
